import React, { useState, useCallback } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  useMediaQuery,
  styled,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';

interface NavigationItem {
  label: string;
  path: string;
  description: string;
}

const StyledButton = styled(Typography)<{
  component?: React.ElementType;
}>(({ theme }) => ({
  padding: theme.spacing(1, 2),
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '1rem',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    transform: 'translateY(-2px)',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9rem',
    padding: theme.spacing(0.75, 1.5),
  },
}));

const StyledMenuItem = styled(MenuItem)<{
  component?: React.ElementType;
}>(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  minWidth: 200,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));

const StyledLogo = styled(Box)(({ theme }) => ({
  width: '200px',
  aspectRatio: 'auto',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('md')]: {
    width: '150px',
  },
}));

const navigationItems: NavigationItem[] = [
  { label: 'O nas', path: '/', description: 'Poznaj naszą firmę i wartości' },
  { label: 'Doradztwo', path: '/konsulting-doradztwo', description: 'Profesjonalne usługi doradcze' },
  { label: 'Rejestracja spółek', path: '/rejestracja-spolek', description: 'Kompleksowa pomoc w rejestracji spółek' },
  { label: 'Księgowość', path: '/ksiegowosc', description: 'Usługi księgowe dla firm' },
  { label: 'Finansowanie', path: '/finansowanie', description: 'Rozwiązania finansowe dla biznesu' },
  { label: 'Doradztwo imigracyjne', path: '/imigracje', description: 'Usługi imigracyjne' },
];

const Navbar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <AppBar position="static" elevation={0} color="transparent">
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledLogo>
          <img src="/logo.png" alt="Logo firmy" />
        </StyledLogo>
        {isMobile ? (
          <IconButton
            size="large"
            edge="end"
            color="primary"
            aria-label="menu"
            onClick={handleMenu}
            aria-controls="menu-mobile"
            aria-haspopup="true"
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Box
            sx={{
              display: 'flex',
              gap: { xs: 1, sm: 2, md: 3 },
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {navigationItems.map((item) => (
              <RouterLink
                key={item.path}
                to={item.path}
                style={{ textDecoration: 'none', color: theme.palette.primary.main }}
              >
                <StyledButton
                  variant="body1"
                  component="div"
                  aria-label={item.description}
                >
                  {item.label}
                </StyledButton>
              </RouterLink>
            ))}
          </Box>
        )}
        <Menu
          id="menu-mobile"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {navigationItems.map((item) => (
            <RouterLink
              key={item.path}
              to={item.path}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <StyledMenuItem
                onClick={handleClose}
                aria-label={item.description}
              >
                {item.label}
              </StyledMenuItem>
            </RouterLink>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
