import React from 'react';
import { AppBar, Toolbar, Container, Box } from '@mui/material';
import Navbar from './Navbar';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'sticky',
  top: 0,
  transition: 'all 0.3s ease-in-out',
  zIndex: theme.zIndex.appBar,
  '@media print': {
    display: 'none',
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1.5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
}));

const StyledLogo = styled(Box)(({ theme }) => ({
  width: '200px', // lub inna preferowana szerokość
  aspectRatio: 'auto',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('md')]: {
    width: '150px', // mniejszy rozmiar dla widoku mobilnego
  },
}));

const Header: React.FC = () => {
  return (
    <StyledAppBar elevation={0}>
      <StyledContainer maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <StyledLogo>
            <img src="/logo.png" alt="Logo firmy" />
          </StyledLogo>
          <Navbar />
        </Toolbar>
      </StyledContainer>
    </StyledAppBar>
  );
};

export default Header;
