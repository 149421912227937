import React, { Suspense } from 'react';
import { ThemeProvider, CssBaseline, Box, CircularProgress } from '@mui/material';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion, LazyMotion, domAnimation } from 'framer-motion';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { theme } from '../styles/theme';

// Lazy loading dla stron
const Home = React.lazy(() => import('../pages/Home'));
const ConsultingDoradztwo = React.lazy(() => import('../pages/ConsultingDoradztwo'));
const CompanyRegistration = React.lazy(() => import('../pages/CompanyRegistration'));
const ImmigrationServices = React.lazy(() => import('../pages/ImmigrationServices'));
const AccountingPage = React.lazy(() => import('../pages/AccountingPage'));
const FinancingPage = React.lazy(() => import('../pages/Financing'));
import emailjs from '@emailjs/browser';

emailjs.init('CrCZdcgs_hCTtO8Ad');
// Animacje dla stron
const pageVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -20,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.3,
};

// Komponent loadera
const LoadingFallback = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="50vh"
    role="status"
    aria-label="Ładowanie strony"
  >
    <CircularProgress />
  </Box>
);

// Wrapper dla stron z animacją
const PageWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={pageTransition}
    >
      {children}
    </motion.div>
  );
};

// Routing configuration
const routes = [
  { path: '/', element: <Home />, label: 'Strona główna' },
  { path: '/konsulting-doradztwo', element: <ConsultingDoradztwo />, label: 'Konsulting i doradztwo' },
  { path: '/rejestracja-spolek', element: <CompanyRegistration />, label: 'Rejestracja spółek' },
  { path: '/ksiegowosc', element: <AccountingPage />, label: 'Księgowość' },
  { path: '/finansowanie', element: <FinancingPage />, label: 'Finansowanie' },
  { path: '/imigracje', element: <ImmigrationServices />, label: 'Doradztwo imigracyjne' },
];

const App: React.FC = () => {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LazyMotion features={domAnimation}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Header />
          <Box
            component="main"
            sx={{
              flex: 1,
              width: '100%',
              position: 'relative',
            }}
          >
            <Suspense fallback={<LoadingFallback />}>
              <AnimatePresence mode="wait" initial={false}>
                <Routes location={location} key={location.pathname}>
                  {routes.map(({ path, element, label }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <PageWrapper>
                          <div role="main" aria-label={label}>
                            {element}
                          </div>
                        </PageWrapper>
                      }
                    />
                  ))}
                </Routes>
              </AnimatePresence>
            </Suspense>
          </Box>
          <Footer />
        </Box>
      </LazyMotion>
    </ThemeProvider>
  );
};

export default App;
